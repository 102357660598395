import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Page from '../components/Page';
import CenteredInteriorHero from '../components/CenteredInteriorHero';
import {RawPageProps, AdvocacyLandingPageProps} from '../interfaces';
import {propsPageStyle} from '../style/PageStyle';
import NotFoundPage from './404';
import CardGrid, {_propsStaticCardGrid} from '../components/CardGrid';
import {
  article as _article,
  tag as _tag,
  category as _category,
} from '../interfaces';

const advocacyQuery = graphql`
  query {
    sanityAdvocacyLandingPage {
      id: _key
      __typename
      metaTitle
      metaDescription
      socialShareDescription
      socialShareImage {
        src {
          asset {
            url
          }
        }
      }
      slug {
        current
      }
      showPreFooter
      fullUrl
      hero {
        copy: _rawCopy(resolveReferences: {maxDepth: 3})
        eyebrow
        headline
        variant
        backgroundImage {
          src {
            asset {
              fluid(maxWidth: 2000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      parentPage {
        ... on SanityPage {
          id
          slug {
            current
          }
        }
      }
    }

    allSanityAdvocacyCategory(sort: {fields: name}) {
      nodes {
        id
        name
        slug {
          current
        }
      }
    }
    allSanityAdvocacyTags {
      nodes {
        id
        name
        slug {
          current
        }
      }
    }
    allSanityAdvocacyArticlePage {
      nodes {
        id
        title
        metaDescription
        publishedDate(formatString: "MMM D, YYYY")
        sortDate: publishedDate(formatString: "X")
        slug {
          _key
          current
        }
        fullUrl
        previewImage {
          src {
            asset {
              url
              fluid(maxWidth: 350) {
                __typename
                ...GatsbySanityImageFluid
              }
            }
          }
          alt
          title
        }
      }
    }
  }
`;

interface _props
  extends RawPageProps,
    AdvocacyLandingPageProps,
    propsPageStyle {
  gridProps: _propsStaticCardGrid;
}

const AdvocacyLandingPage = (props: _props) => (
  <Page {...props} pageContext={props.pageContext} pagePath={props.pagePath}>
    <CenteredInteriorHero {...props.hero} />
    <CardGrid {...props.gridProps} />
  </Page>
);

export default (props: RawPageProps) => {
  const results = useStaticQuery(advocacyQuery);
  const {
    sanityAdvocacyLandingPage,
    allSanityAdvocacyCategory,
    allSanityAdvocacyTags,
    allSanityAdvocacyArticlePage,
  } = results;

  const articles = ((allSanityAdvocacyArticlePage?.nodes ||
    []) as _article[]).sort(
    (a, b) => parseInt(b.sortDate) - parseInt(a.sortDate),
  );

  const categories = (allSanityAdvocacyCategory?.nodes || []) as _category[];
  const tags = (allSanityAdvocacyTags?.nodes || []) as _tag[];

  const gridProps: _propsStaticCardGrid = {
    categories,
    tags,
    articles,
    noTaxonomy: true,
  };

  if (sanityAdvocacyLandingPage) {
    return (
      <AdvocacyLandingPage
        {...{
          ...sanityAdvocacyLandingPage,
          pageContext: props.pageContext,
          pagePath: props.location.pathname,
          gridProps,
        }}
      />
    );
  }

  return <NotFoundPage {...props.pageContext} />;
};
